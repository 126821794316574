import React, { FC } from "react"

import { IPageSchema } from "gatsby-theme-nurofen/src/components/common/PageSchema/models"

const PageSchema: FC<IPageSchema> = ({ siteSettings, brandSettings }) => {
  const { siteUrl, siteName } = siteSettings

  const jsonLd = {
    "@context": "http://schema.org",
    "@type": "Organization",
    name: siteName,
    url: siteUrl,
    logo: `${siteUrl}favicon-32x32.png?v=2c6fbdd95a0cf9ad46ea3ffa198de1aa`,
    sameAs: [...brandSettings?.socialNetworks?.map(({ value }) => value)],
  }
  const structuredData = JSON.stringify(jsonLd)

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: structuredData }}
    />
  )
}

export default PageSchema
